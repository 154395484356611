/* eslint-disable */
// Google Maps Dynamic Library Import. https://developers.google.com/maps/documentation/javascript/load-maps-js-api
const gmapApiLoaderFunction = (g: any) => {
    let h: any;
    let a;
    let k;
    const p = 'The Google Maps JavaScript API';
    const c = 'google';
    const l = 'importLibrary';
    const q = '__ib__';
    const m = document;
    let b: any = window;
    b = b[c] || (b[c] = {});
    const d = b.maps || (b.maps = {});
    const r = new Set();
    const e = new URLSearchParams();
    const u = () =>
        h ||
        (h = new Promise(async (f, n) => {
            await (a = m.createElement('script'));
            e.set('libraries', `${[...r]}`);
            for (k in g)
                e.set(
                    k.replace(/[A-Z]/g, (t) => `_${t[0].toLowerCase()}`),
                    g[k],
                );
            e.set('callback', `${c}.maps.${q}`);
            a.src = `https://maps.${c}apis.com/maps/api/js?${e}`;
            d[q] = f;
            a.onerror = () => (h = n(Error(`${p} could not load.`)));
            const scriptNonce: any = m.querySelector('script[nonce]');
            a.nonce = scriptNonce?.nonce || '';
            m.head.append(a);
        }));
    d[l]
        ? console.warn(`${p} only loads once. Ignoring:`, g)
        : (d[l] = (f: any, ...n: any[]) => r.add(f) && u().then(() => d[l](f, ...n)));
};

/**
 *
 * @param {object} gMapApiKey Google Maps API key
 * @param {string} library Google Maps Library name
 * @returns {Promise<typeof any>}
 */
export function loadGoogleMapsAPI(gMapApiKey: string, library: any) {
    const w: any = window;
    if (!w.google?.maps) {
        console.log('!window.google?.maps line 145');
        gmapApiLoaderFunction({
            key: gMapApiKey,
            v: 'weekly',
            // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
            // https://developers.google.com/maps/documentation/javascript/versions
            // Add other bootstrap parameters as needed, using camel case.
        });
    }
    return w.google.maps.importLibrary(library);
}

// Function to get the user's location and extract the zip code
export const getLocation = async (apiKey: string) => {
    const w: any = window;
    return new Promise<string>((resolve, reject) => {
        fetch(`https://ipapi.co/json/?key=${apiKey}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'fail') {
                    reject(new Error('Failed to retrieve location data.'));
                } else {
                    // Use the latitude and longitude from ipapi.com
                    const latlng = {
                        lat: data.latitude,
                        lng: data.longitude,
                    };

                    // Initialize the Google Maps Geocoder
                    const geocoder = new w.google.maps.Geocoder();

                    // Use the Google Maps Geocoding API to get the zip code
                    geocoder.geocode({ location: latlng }, (results: any, status: any) => {
                        if (status === 'OK') {
                            if (results[0]) {
                                const zipCode = results[0].address_components.find((component: any) =>
                                    component.types.includes('postal_code'),
                                );
                                if (zipCode) {
                                    resolve(zipCode.short_name);
                                } else {
                                    reject(new Error('Zip code not found'));
                                }
                            } else {
                                reject(new Error('No results found'));
                            }
                        } else {
                            reject(new Error(`Geocoder failed due to: ${status}`));
                        }
                    });
                }
            })
            .catch((error) => {
                reject(new Error(`Error fetching location: ${error.message}`));
            });
    });
};

/* eslint-enable */
